import React, { useEffect, useState } from 'react';
import { Image, makeStyles, shorthands } from '@fluentui/react-components';

import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Footer from './Components/Footer'
import Meetings from './Components/Volunteers/Meetings';
import { isMobile } from 'react-device-detect';
import NavigationMenu from './Shared/NavigationMenu';

const useStyles = makeStyles({
  root: {
    //...shorthands.border("2px", "solid", "#ccc"),
    ...shorthands.overflow("hidden"),

    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%"
  },
  rootMobile: {
    //...shorthands.border("2px", "solid", "#ccc"),
    ...shorthands.overflow("hidden"),

    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    width: "100%"
  },
  bannerImage: {
    height: "300px",
    width: "100%",
    ...shorthands.padding("unset !important")
  },
  menu: {
    height: "100%"
  },
  content: {
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    height: "100vh",//"calc(100vh - 50px)",
    width: "100%"
  },
  contentPane: {
    ...shorthands.padding("25px", "25px"),
/*     backgroundColor: "white",
    color: "black", */
    width: "100%"
  },
  footer: {
    ...shorthands.padding("5px", "5px"),
    width: "100%"
  },
  button: {
    position: "absolute",
    left: "l0px",
    height: "50px",
    width: "50px"
  }
});

//type DrawerType = Required<DrawerProps>["type"];

const App: React.FC = () => {
  const styles = useStyles();

  // Side menu states
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [orientation, setOrientation] = useState<string>('');

  const checkOrientation = () => {
    if(window.matchMedia("(orientation: portrait)").matches) {
      setOrientation('portrait');
    }
    else if(window.matchMedia("(orientation: landscape)").matches) {
      setOrientation('landscape');
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkOrientation);
    checkOrientation(); // Initial check
    return () => window.removeEventListener("resize", checkOrientation);
  }, [])

  //const [type, setType] = useState<DrawerType>("inline");

  //const [data, setData] = useState('');
  //const [isPending, setIsPending] = useState(false);

/*   const onMediaQueryChange = useCallback(
    ({ matches }: {matches: boolean}) => setType(matches ? "overlay" : "inline"),
    [setType]
  ); */

/*   useEffect(() => {
    const match = window.matchMedia("(max-width: 720px)");

    if(match.matches) {
      setIsOpen(false);
    }
    else {
      setIsOpen(true);
    }

    match.addEventListener("change", onMediaQueryChange);
    return () => match.removeEventListener("change", onMediaQueryChange);
  }, [onMediaQueryChange]); 

  useEffect(() => {
    React.startTransition(() => {
      setIsPending(true);
      fetch(`/api/GetMeetings`)
        .then(response => response.json())
        .then(({ text }) => {
          setData(text);
          setIsPending(false);
        });
    })
  }, []);*/

  return (
    <div className={styles.root}>
{/*       <div >        
          <Button className={styles.button} appearance="primary" onClick={() => setIsOpen(!isOpen)}>
            <FaBars />
          </Button>
          <NavigationDrawer 
          open={isOpen}
          setOpen={setIsOpen}
          position="start"
        />
      </div> */}
      <NavigationMenu 
        open={isOpen}
        setOpen={setIsOpen}
        position="start" />
      <div className={styles.content}>

        <Image className={styles.bannerImage} src={process.env.PUBLIC_URL + (isMobile && orientation === 'portrait' ? '/banner-mobile-v3.png' : '/banner-desktop-v3.png')}></Image>
        <div className={styles.contentPane}>
          
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/volunteers/meetings' element={<Meetings />} />
          </Routes>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
  </div>
  );
};

export default App;
