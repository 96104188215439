import * as React from "react";
import { DrawerBody, DrawerProps, InlineDrawer, MenuGroupHeader, MenuItemLink, MenuList, makeStyles, shorthands } from "@fluentui/react-components";
import { useLocation } from "react-router-dom";

type NavigationDrawerProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    position: DrawerProps["position"];
}

const useStyles = makeStyles({
    inlineDrawer: {
        height: "100vh",
        
    },
    drawerBody: {
        ...shorthands.padding("unset !important")
    },
    drawerContent: {
        display: "flex",
        flexDirection: "column"
    },
    hamburgerMenu: {
//        position: "absolute",
        left: "0px",
        height: "50px",
        width: "50px",
        zIndex: "100",
        visibility: "hidden"
    },
    drawerMenu: {
        marginTop: "50px",
        ...shorthands.padding("20px","unset")
    }
});

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
    open,
    setOpen,
    position
}) => {
    const location = useLocation();
    const styles = useStyles();

    const isCurrentPage = (path: string) => location.pathname === path;

    return (
        <InlineDrawer
            className={styles.inlineDrawer}
            separator 
            open={open} 
            position={position}>
{/*             <DrawerHeader>
                <DrawerHeaderTitle>

                    Inline Drawer
                </DrawerHeaderTitle>
            </DrawerHeader> */}

            <DrawerBody className={styles.drawerBody}>
                <div className={styles.drawerContent}>
                    <div>
{/*                         <Button className={styles.hamburgerMenu} appearance="primary" onClick={() => setOpen(!open)}>
                            <FaBars />
                        </Button> */}
                    </div>
                    <div>
                        <MenuList className={styles.drawerMenu}>
                            <MenuItemLink className={isCurrentPage('/') ? 'active' : ''} href="/">Home</MenuItemLink>
                            <MenuGroupHeader>Volunteers</MenuGroupHeader>
                            <MenuItemLink className={isCurrentPage('/volunteers/meetings') ? 'active' : ''} href="/volunteers/meetings">Planning Meetings</MenuItemLink>
                        </MenuList>
                    </div>
                </div>

            </DrawerBody>
        </InlineDrawer>
    );
};

export default NavigationDrawer